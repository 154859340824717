define("discourse/plugins/discourse-gamification/discourse/components/modal/leaderboard-info", ["exports", "@ember/template", "discourse/components/d-modal", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _template, _dModal, _dIcon, _i18n, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LeaderboardInfo = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DModal
      @title={{i18n "gamification.leaderboard.modal.title"}}
      @closeModal={{@closeModal}}
      class="leaderboard-info-modal"
    >
      <:body>
        {{icon "award"}}
        {{htmlSafe (i18n "gamification.leaderboard.modal.text")}}
      </:body>
    </DModal>
  
  */
  {
    "id": "fo8tVKhk",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"leaderboard-info-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"gamification.leaderboard.modal.title\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n      \"],[1,[28,[32,2],[\"award\"],null]],[1,\"\\n      \"],[1,[28,[32,3],[[28,[32,1],[\"gamification.leaderboard.modal.text\"],null]],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\"],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-gamification/discourse/components/modal/leaderboard-info.js",
    "scope": () => [_dModal.default, _i18n.default, _dIcon.default, _template.htmlSafe],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = LeaderboardInfo;
});